import React, { useState, useRef, useEffect } from "react";
import "./card.css";
import { Box, Grid, Typography, Tooltip, Zoom } from "@mui/material";
import { GetTimeString } from "../../utiles";

export interface URIProp {
  uri: any;
  name: string;
  assetID: string;
  stakedTime?: any;
  onClick?: any;
  isSelected?: boolean;
  onClaim?: any;
  onStake?: any;
  nftData?: any;
}

const NFTCard = ({
  uri,
  nftData,
  name,
  assetID,
  stakedTime,
  isSelected,
  onClick,
  onClaim = null,
  onStake = null,
}: URIProp) => {
  // Style for the main NFT card container
  const box_style = {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    flexDirection: "column",
    margin: "auto",
    height: "100%",
    maxWidth: "200px",
    width: "90%",
    bgcolor: "#1a1f3c",
    borderRadius: 2,
    border: "1px solid " + (isSelected ? "#1fff57" : "black"),
    backgroundImage: "radial-gradient(circle, #5c0067 0%, #06313a 100%)",
    cursor: "pointer", // Indicate that the card is clickable
  };

  // State for managing hover and tooltip
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const cardRef = useRef(null);

  // Timer for countdown
  const [uTime, setUTime] = useState(0);
  let timer: any = null;

  // Start the countdown timer
  const startTimer = () => {
    if (timer == null) {
      timer = setInterval(() => {
        setUTime((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timer);
            timer = null;
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
  };

  // Set up and clear the timer when stakedTime changes
  useEffect(() => {
    if (stakedTime && stakedTime > 0) {
      clearInterval(timer);
      timer = null;

      const currentUnixTime = Math.floor(new Date().getTime() / 1000);
      let delayTime = stakedTime - currentUnixTime;

      if (delayTime > 0) {
        setUTime(delayTime);
        startTimer();
      }
    }

    return () => {
      clearInterval(timer);
      timer = null;
    };
  }, [stakedTime]);

  // Handle NFT card click
  const onClickNFT = (id: any) => {
    if (onClick) onClick(id);
  };

  // Show tooltip when hovered for more than 500ms
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isHovered) {
      timeoutId = setTimeout(() => {
        setTooltipOpen(true);
      }, 500);
    } else {
      setTooltipOpen(false);
    }

    return () => clearTimeout(timeoutId);
  }, [isHovered]);

  // Handle mouse enter and leave for hover state
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Function to render attribute items in the tooltip
  const renderAttributeItem = (label: string, value: any) => {
    if (!value) return null;
    return (
      <Typography
        variant="body2"
        sx={{
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #616161",
          pb: 0.5,
          mb: 0.5,
        }}
      >
        <span style={{ color: "#ccc" }}>{label}:</span>
        <span>{value}</span>
      </Typography>
    );
  };

  return (
    <Grid item xl={2} md={2} sm={3} xs={3} sx={{ mb: 3 }}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#1a203c",
              "& .MuiTooltip-arrow": {
                color: "#1a203c",
              },
              p: 2,
              maxWidth: "300px", // Increase max width for more details
              maxHeight: "400px",
              overflowY: "auto",
            },
          },
        }}
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="right-start"
        arrow
        title={
          <Box>
            {/* Render attributes */}
            {renderAttributeItem("Level", nftData?.level)}
            {renderAttributeItem("Asset ID", nftData?.asset_id)}
            {renderAttributeItem("Base", nftData?.data?.Base)}
            {renderAttributeItem("Eyes", nftData?.data?.Eyes)}
            {renderAttributeItem("Cloth", nftData?.data?.Cloth)}
            {renderAttributeItem("Horns", nftData?.data?.Horns)}
            {renderAttributeItem("Mouth", nftData?.data?.Mouth)}
            {renderAttributeItem("Wings", nftData?.data?.Wings)}
            {renderAttributeItem("Earring", nftData?.data?.Earring)}
            {renderAttributeItem("Glasses", nftData?.data?.Glasses)}
            {renderAttributeItem("Necklace", nftData?.data?.Necklace)}
            {renderAttributeItem("Background", nftData?.data?.Background)}
            <Box sx={{ mt: 1 }}>{/* Render additional details */}</Box>
          </Box>
        }
      >
        <Box
          sx={box_style}
          ref={cardRef}
          onClick={() => onClickNFT(assetID)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* Top section with countdown timer */}
          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "white", flexGrow: 1 }}
            >
              {uTime < 1 ? "-" : GetTimeString(uTime)}
            </Typography>
          </Box>

          {/* Middle section with NFT image */}
          <Box sx={{ width: "90%", textAlign: "center", m: "auto" }}>
            <img
              style={{ width: "100%", borderRadius: "4px" }}
              src={uri}
              alt="card"
            />
          </Box>

          {/* Bottom section with NFT name and view link */}
          <Box sx={{ p: 1 }}>
            {onClaim && (
              <button
                onClick={onClaim}
                style={{
                  border: "1px solid #ea923e",
                  padding: "4px 8px",
                  borderRadius: "8px",
                  color: "red",
                  fontSize: "12px",
                  textDecoration: "none",
                  display: "inline-block",
                  marginTop: "5px",
                }}
              >
                Claim
              </button>
            )}
            <Typography variant="subtitle2" sx={{ color: "white" }}>
              {name}
            </Typography>
            {nftData.tool_config && (
              <Typography variant="subtitle2" sx={{ color: "white" }}>
                {nftData.tool_config.reward_amount > 0
                  ? `${nftData.tool_config.reward_amount} XP`
                  : `${parseFloat(nftData.tool_config.reward_token)} GRASS`}
                / DAY
              </Typography>
            )}
            <Typography variant="subtitle2" sx={{ color: "white" }}>
              Level {nftData.level}
            </Typography>{" "}
            <a
              href={`https://wax.atomichub.io/explorer/asset/wax-mainnet/${assetID}`}
              target="_blank"
              rel="noreferrer"
              style={{
                border: "1px solid #ea923e",
                padding: "4px 8px",
                borderRadius: "8px",
                color: "white",
                fontSize: "12px",
                textDecoration: "none",
                display: "inline-block",
                marginTop: "5px",
              }}
            >
              View on Atomichub
            </a>
          </Box>
        </Box>
      </Tooltip>
    </Grid>
  );
};

export default NFTCard;
