import React from "react";

import { Box, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from '@mui/material';

interface Props {
    value: number
    onChange?: any
    token: string
    img: any
    type: string
    balance: string
    setPercent?: any
}

const inputStyle: any = {

    color: "rgb(255, 255, 255)",
    width: "100%",
    position: "relative",
    fontWeight: "500",
    outline: "none",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderImage: "initial",
    flex: "1 1 auto",
    fontSize: "40px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0px",
    appearance: "textfield",
    height: "70px",
    background: "none",
    borderBottom: "0.0625rem solid rgb(92, 103, 125)",
    marginRight: "10px",
}

const TokenSwapInput = ({ value, onChange, token, img, type, balance, setPercent }: Props) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Adjust 'sm' as 

    const selectAll = () => {

    }

    return (
        <>
            <Box >
                <Box sx={{ display: "flex", alignItems: "center", color: "white" }}>
                    <input style={{ ...inputStyle, fontSize: isMobile ? "24px" : "40px" }} placeholder="0.0" value={value} onChange={onChange} disabled={type == "receive"} pattern="^([0-9]+(\.?[0-9]?[0-9]?d{0, 2}?)?)" />
                    <Box sx={{ alignItems: "center", width: "60%", fontWeight: 500 }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box sx={{ width: { xs: "30px", sm: "30px", md: "40px" }, marginRight: "8px" }}><img style={{ display: "flex", alignItems: "center" }} src={img} /></Box>

                            <Typography id="modal-modal-title" variant="h6" component="p" sx={{ fontWeight: "bold", fontSize: { xs: "16px", sm: "16px", md: "24px" } }}>
                                {token}
                            </Typography>
                        </Box>
                        <Box sx={{ width: "100%", mt: 1 }} >
                            <p style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>Balance : {balance}</p>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default TokenSwapInput;