import { Box, Button, FormControl, FormHelperText, Grid, Input, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { GetPeriodTimeString, GetTimeString, RemoveDecimalFromBalance } from "../utiles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ClaimStakePool, StakePool } from "../store/user/actions";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
const box_style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "270px",
    width: "270px",
    height: "360px",
    // bgcolor: '#1a1f3c',
    // borderRadius: 2,
    // border: "1px solid black",
    // backgroundImage: "radial-gradient(circle, #5c0067 0%, #06313a 100%)",
    backgroundImage: "url(/images/card_bg.png)",
    backgroundSize: "100% 100%",
}




export const TournamentCard = ({ id, start_time, end_time, winner1, winner2, is_entered, setId, is_open }: any) => {

    const btnStyle = {
        width: "100%",
        minWidth: "2px",
        color: "black",
        height: "100%",
        fontWeight: "bold",
        // backgroundColor: "#ea923e",
        borderColor: "#0de69d",
        borderRadius: "15px",
        fontSize: "18px",
        padding: "16px",
        // pointerEvents: !is_open ? "none" : "active",
        // opacity: !is_open ? "0.5" : "1"
    }

    const dispatch = useDispatch<any>();

    const [value, setValue] = useState(0);

    const [uTime, setUTime] = useState(0);
    let timer: any = null;
    const startTimer = () => {
        if (timer == null) {
            timer = setInterval(() => {
                setUTime((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(timer);
                        timer = null;
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
    }


    const onEnterTournament = () => {

        if (uTime > 0 && !is_entered)
            setId(id);
        else {
            console.log("view result");
            setId(-id);
        }
    }

    const [status, setStatus] = useState("");

    useEffect(() => {
        const currentUnixTime = Math.floor(new Date().getTime() / 1000);
        let waitTime = end_time - currentUnixTime;
        setStatus(waitTime > 0 ? "active" : "finished");

        if (waitTime > 0) {
            if (timer) {
                clearInterval(timer);
                timer = null;
            }
            setUTime(waitTime);
            startTimer();
        }

        return () => {
            if (timer) {
                clearInterval(timer);
                timer = null;
            }
        };

    }, []);
    // 
    return (
        <Grid xl={3} md={3} sm={6} xs={12} sx={{ mb: 3 }}>
            <Box sx={box_style}>
                <Box sx={{ height: "100%", p: 4, boxSizing: "border-box", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        {/* <Checkbox {...label} /> */}
                        <Typography className="name" variant="h6" style={{ fontSize: "24px", color: "#0da97f", padding: "8px", fontWeight: "bold" }} component="p" sx={{ flexGrow: 1 }}>
                            - {id} -
                        </Typography>
                    </Box>
                    <>
                        {uTime > 0
                            ? <Box>
                                <Typography className="name" variant="h6" style={{ fontSize: "20px", width: "90%", margin: "auto", color: "white" }} component="p" sx={{ flexGrow: 1 }}>
                                    Entry starts <br />
                                    ~ {GetTimeString(uTime || 0)}
                                </Typography>
                            </Box>
                            : <Box>
                                <Typography className="name" variant="h6" style={{ fontSize: "20px", width: "90%", margin: "auto", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }} component="p" sx={{ flexGrow: 1 }}>
                                    <EmojiEventsIcon sx={{ color: "yellow" }} />&nbsp; {winner1.user}
                                </Typography>
                                <Typography className="name" variant="h6" style={{ fontSize: "20px", width: "90%", margin: "auto", color: "white", display: "flex", alignItems: "center", justifyContent: "center" }} component="p" sx={{ flexGrow: 1 }}>
                                    <EmojiEventsIcon sx={{ color: "white" }} />&nbsp; {winner2.user}
                                </Typography>
                            </Box>}

                        <Box sx={{ width: "100%", pointerEvents: (uTime > 0 && is_entered) ? "none" : "active", opacity: (uTime > 0 && is_entered) ? 0.5 : 1 }}>
                            <Grid container style={{ justifyContent: "space-between", width: "90%", margin: "auto", marginTop: "5px" }}>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            bgcolor: uTime > 0 ? '#1dff56' : '#717171',
                                            fontWeight: "bold",
                                            boxShadow: 1,
                                            borderRadius: 3,
                                            width: "100%",
                                            height: "70%"
                                        }}
                                    >
                                        <Button sx={{ ...btnStyle, pointerEvents: (uTime > 0 && is_entered && is_open) ? 'none' : 'active', opacity: (uTime > 0 && is_entered && !is_open) ? 0.4 : 1 }} onClick={() => { onEnterTournament() }}>
                                            {(uTime > 0 && is_open) ? is_entered ? "Entered" : "Enter" : "View Result"}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                </Box>
            </Box>
        </Grid >
    );

}