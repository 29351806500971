import { Box, Button, Grid, Slider, Typography } from "@mui/material";
import InventoryItemCard from "./InventoryItemCard";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { useEffect, useState } from "react";
import { EnterTournament } from "../store/user/actions";


// let uri_prefix = "https://ipfs.neftyblocks.io/ipfs/";
let uri_prefix = "https://atomichub-ipfs.com/ipfs/";


export const TournamentCardView = ({ id }: any) => {

    const dispatch = useDispatch<any>();
    const userModule = useSelector((state: AppState) => state.userModule);
    const loginInfo = userModule.loginInfo;
    const gameStatus = userModule.gameStatus;

    const [fawnNfts, setFawnNfts] = useState([]);
    const [selectedNfts, SetSelectedNfts] = useState([]);

    useEffect(() => {

        let _fawns = loginInfo.assets?.filter((item: any) => item?.schema?.schema_name == "fawn");
        setFawnNfts(_fawns);


    }, [JSON.stringify(loginInfo.assets)]);

    const [value, setValue] = useState(0);


    const EnterTour = () => {

        dispatch(EnterTournament(selectedNfts, id));

    }

    const onClickNFT = (asset_id: any) => {
        console.log(asset_id);
        let _selectedFawn: any = [...selectedNfts];
        if (_selectedFawn.find((id: any) => id == asset_id)) {
            _selectedFawn = _selectedFawn.filter((id: any) => id != asset_id);
            setValue(value - 1);
        } else {
            _selectedFawn.push(asset_id);
            setValue(value + 1);
        }

        SetSelectedNfts(_selectedFawn);
    }

    const onUpdateSelectedNFTs = (value: number) => {
        setValue(value);

        let _selectedFawn: any = [];

        fawnNfts?.map((item: any, index: number) => {
            if (index < value)
                _selectedFawn.push(item.asset_id);
        });

        SetSelectedNfts(_selectedFawn);
        console.log(_selectedFawn);
    }

    return (<>

        <Box sx={{ width: "100%" }}>
            <Box sx={{ bgcolor: "#41308530", width: { xs: "100%", md: "100%" }, margin: "auto", borderRadius: "8px" }}>
                <Box sx={{ maxHeight: "600px", overflowY: "scroll", height: "50vh", mt: 2 }}>
                    <Grid container sx={{ p: 2 }}>
                        {fawnNfts?.map((item: any, index: number) => (
                            <InventoryItemCard
                                asset_id={item.asset_id}
                                level={item.level}
                                name={item.data.name}
                                img_url={uri_prefix + item.data.img}
                                isSelected={selectedNfts.find((ids) => ids == item.asset_id)}
                                onClick={onClickNFT}
                            />
                        ))}
                    </Grid>
                </Box>

            </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
            <Slider
                aria-label="Temperature"
                defaultValue={30}
                min={fawnNfts?.length > 0 ? 0 : 0}
                max={fawnNfts?.length > 0 ? fawnNfts?.length : 0}
                onChange={(e, value: any) => { onUpdateSelectedNFTs(value) }}
                color="secondary"
                value={value}

                sx={{ width: "100%" }}
            />

            <Typography className="name" variant="h6" component="p" sx={{ flexGrow: 1, fontSize: { sm: "16px", md: "30px" }, color: "white", padding: "8px" }}>
                {value}  {value == 1 ? " Fawn is" : " Fawns are"} Selected
            </Typography>
            <Typography className="name" variant="h6" component="p" sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: { sm: "10px", md: "20px" }, color: "white", padding: "8px" }}>
                <Box sx={{ width: "30px", display: "flex", alignItems: "center" }}>
                    <img src="/images/info-icon.png" />
                </Box>
                <span>Fee: {selectedNfts.length} Antlers</span>
            </Typography>
        </Box>

        <Box >
            <Button color="primary" variant="contained" onClick={() => { EnterTour() }}>Enter Tournament</Button>
        </Box>
    </>)
}