export interface LoginInfo {
  session: any;
  account: string;
  balance: any;
  assets: any;
  isLogin: boolean;
  open_worlds: Array<number>;
  staking_pool: Array<any>;
  tool_configs: Array<any>;
}

export interface UserState {
  loginInfo: LoginInfo;
  gameStatus: any;
}

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const WORLD_SELECT = "WORLD_SELECT";

export const EXPLORATION_SUCCESS = "EXPLORATION_SUCCESS";
export const EXPLORATION_ERROR = "EXPLORATION_ERROR";

export const BUY_WORLD = "BUY_WORLD";
export const SHOW_ALERT = "SHOW_ALERT";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

export const CLAIM_STAKE = "CLAIM_STAKE";
export const STAKE_SUCCESS = "STAKE_SUCCESS";

export const BUY_GOLD = "BUY_GOLD";
export const GET_LEADERBOARD = "GET_LEADERBOARD";

export const STAKE_TOOL_SUCCESS = "STAKE_TOOL_SUCCESS";
export const LEVELUP_SUCCESS = "LEVELUP_SUCCESS";
export const LEVELUP_BOOST_SUCCESS = "LEVELUP_BOOST_SUCCESS";

export const ENTER_TOURNAMENT_SUCCESS = "ENTER_TOURNAMENT_SUCCESS";
export const GET_TOURNAMENT_LIST = "GET_TOURNAMENT_LIST";

export const GET_CONFIGS = "GET_CONFIGS";

interface LoginRequest {
  type: typeof LOGIN_REQUEST;
  payload: {};
}

interface LoginSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: {
    session: any;
    assets: Array<any>;
    balance: string;
    world_cost: Array<any>;
    open_worlds: Array<number>;
    reward_info: Array<any>;
    pool_info: Array<any>;
    staking_pool: Array<any>;
  };
}

interface LoginError {
  type: typeof LOGIN_ERROR;
  payload: {
    error: any;
  };
}

interface BuyWorld {
  type: typeof BUY_WORLD;
  payload: {
    world: number;
  };
}

interface SetWorldSuccess {
  type: typeof WORLD_SELECT;
  payload: {
    world: number;
  };
}

interface ExplorationSuccess {
  type: typeof EXPLORATION_SUCCESS;
  payload: {
    asset_ids: Array<any>;
    type: number;
  };
}

interface ExplorationError {
  type: typeof EXPLORATION_ERROR;
  payload: {};
}

interface ClaimSuccess {
  type: typeof CLAIM_STAKE;
  payload: {
    pool_id: number;
  };
}

interface StakeSuccess {
  type: typeof STAKE_SUCCESS;
  payload: {
    pool_id: number;
    amount: any;
  };
}

interface BuyGoldSuccess {
  type: typeof BUY_GOLD;
  payload: {
    amount: number;
    receive_amount: number;
  };
}

interface ShowAlert {
  type: typeof SHOW_ALERT;
  payload: {
    type: string;
    msg: string;
    show: boolean;
  };
}

interface ShowLoading {
  type: typeof SHOW_LOADING;
  payload: {};
}

interface HideLoading {
  type: typeof HIDE_LOADING;
  payload: {};
}

export type UserActionTypes =
  | LoginRequest
  | LoginSuccess
  | LoginError
  | BuyWorld
  | SetWorldSuccess
  | ExplorationSuccess
  | ExplorationError
  | ShowAlert
  | ShowLoading
  | HideLoading
  | ClaimSuccess
  | StakeSuccess
  | BuyGoldSuccess;
