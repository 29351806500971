import { Box, Button, FormControl, FormHelperText, Grid, Input, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { GetPeriodTimeString, GetTimeString, RemoveDecimalFromBalance } from "../../utiles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ClaimStakePool, StakePool } from "../../store/user/actions";

import "./index.css";

export interface StakingPoolCardProps {
    id: number,
    reward_percent: number,
    stake_delay: number,
    is_open: boolean,

    is_staked?: boolean,
    stake_amount?: string,
    cooldown?: number,
}


const box_style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "200px",
    width: "90%",
    height: "320px",
    bgcolor: '#1a1f3c',
    borderRadius: 2,
    border: "1px solid black",
    backgroundImage: "url(/images/card+bg.png)",
    backgroundSize: "100% 100%",
}




export const StakingPoolCard = ({ id, reward_percent, stake_delay, is_open, is_staked, stake_amount, cooldown }: StakingPoolCardProps) => {

    const btnStyle = {
        width: "100%",
        minWidth: "2px",
        color: "black",
        height: "100%",
        fontWeight: "bold",
        // backgroundColor: "#ea923e",
        borderColor: "#0de69d",
        borderRadius: "15px",
        fontSize: "18px",
        padding: "16px",
        pointerEvents: !is_open ? "none" : "active",
        opacity: !is_open ? "0.5" : "1"
    }

    const dispatch = useDispatch<any>();

    const [value, setValue] = useState(0);

    const [uTime, setUTime] = useState(0);
    let timer: any = null;
    const startTimer = () => {
        if (timer == null) {
            timer = setInterval(() => {
                setUTime((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(timer);
                        timer = null;
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
    }

    useEffect(() => {
        if (cooldown && cooldown > 0) {


            if (timer != null) clearInterval(timer);
            timer = null;
            const currentUnixTime = Math.floor(new Date().getTime() / 1000);
            let delayTime = cooldown - currentUnixTime;

            if (delayTime > 0) {
                if (timer) {
                    clearInterval(timer);
                    timer = null;
                }
                setUTime(delayTime);
                startTimer();
            }

        }

        return () => {
            if (timer) {
                clearInterval(timer);
                timer = null;
            }
        };

    }, [cooldown]);

    const onChangeStakeValue = (e: any) => {

        let _value = e.target.value;
        const regex = /^-?\d*\.?\d*$/;

        if (!regex.test(_value)) {
            // If invalid, remove the last character or set to empty
            _value = _value.slice(0, -1);
        }

        setValue(Number(_value));
    }

    const stake = () => {

        if (value < 0 || value == 0) return;

        dispatch(StakePool(id, value));
    }

    const claim = () => {
        let reward_amount = (reward_percent + 100) * parseInt(stake_amount!) / 100 + " ANTLERS";
        dispatch(ClaimStakePool(id, reward_amount));
    }
    // 
    return (
        <Grid xl={3} md={3} sm={6} xs={12} sx={{ mb: 3 }}>
            <Box className={`${(uTime == 0 && is_staked) ? "claim-background" : ""}`} sx={box_style}>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* <Checkbox {...label} /> */}
                    <Typography className="name" variant="h6" style={{ fontSize: "24px", color: "#0da97f", padding: "8px", fontWeight: "bold" }} component="p" sx={{ flexGrow: 1 }}>
                        Pool {id}
                    </Typography>
                </Box>

                {is_staked

                    ? (<>
                        <Box>
                            <Typography className="name" variant="h6" style={{ fontSize: "20px", width: "90%", margin: "auto", color: "white" }} component="p" sx={{ flexGrow: 1 }}>
                                Reward: {reward_percent}%
                            </Typography>
                        </Box>
                        <Box>
                            <Typography className="name" variant="h6" style={{ fontSize: "20px", width: "90%", margin: "auto", color: "white" }} component="p" sx={{ flexGrow: 1 }}>
                                {GetTimeString(uTime || 0)}
                            </Typography>
                        </Box>

                        <Box>
                            <Typography className="name" variant="h6" style={{ fontSize: "20px", width: "90%", margin: "auto", color: "white" }} component="p" sx={{ flexGrow: 1 }}>
                                {RemoveDecimalFromBalance(stake_amount)}
                            </Typography>
                        </Box>

                        <Box style={{ width: "100%" }}>
                            <Grid container style={{ justifyContent: "space-between", width: "90%", margin: "auto", marginTop: "5px" }}>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            bgcolor: '#1dff56',
                                            fontWeight: "bold",
                                            boxShadow: 1,
                                            borderRadius: 3,
                                            width: "100%",
                                            height: "70%"
                                        }}
                                    >
                                        <Button sx={{ ...btnStyle, pointerEvents: uTime > 0 ? 'none' : 'active', opacity: uTime > 0 ? 0.5 : 1 }} onClick={() => { claim() }}>CLAIM</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </>)
                    : (<>
                        <Box>
                            <Typography className="name" variant="h6" style={{ fontSize: "20px", width: "90%", margin: "auto", color: "white" }} component="p" sx={{ flexGrow: 1 }}>
                                Reward: {reward_percent}%
                            </Typography>
                        </Box>

                        <Box>
                            <Typography className="name" variant="h6" style={{ fontSize: "20px", width: "90%", margin: "auto", color: "white" }} component="p" sx={{ flexGrow: 1 }}>
                                Period: {GetPeriodTimeString(stake_delay)}
                            </Typography>
                        </Box>

                        <Box sx={{ width: "100%" }}>
                            <FormControl variant="standard" sx={{ m: 1, mt: 3, color: "white" }}>
                                <Input
                                    id="standard-adornment-weight"
                                    endAdornment={<InputAdornment position="end"><span style={{ color: "grey", fontSize: "20px" }}>ANTLERS</span></InputAdornment>}
                                    aria-describedby="standard-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    disabled={is_open ? false : true}
                                    sx={{
                                        color: "white", '& input': {
                                            textAlign: "right",
                                            pr: "4px",
                                            fontSize: "20px",
                                            color: "#02fff6",
                                            cursor: is_open ? "allowed" : "not-allowed"
                                        },
                                    }}
                                    value={value}
                                    onChange={onChangeStakeValue}
                                />
                            </FormControl>
                        </Box>

                        <Box style={{ width: "100%" }}>
                            <Grid container style={{ justifyContent: "space-between", width: "90%", margin: "auto", marginTop: "5px" }}>
                                <Grid item xs={12}>
                                    <Box
                                        sx={{
                                            bgcolor: '#1dffbe',
                                            fontWeight: "bold",
                                            boxShadow: 1,
                                            borderRadius: 3,
                                            width: "100%",
                                            height: "70%"
                                        }}
                                    >
                                        <Button sx={btnStyle} onClick={() => { stake() }}>{is_open ? "STAKE" : "Comming soon"}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </>)}
            </Box>
        </Grid >
    );

}