import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { MODE } from "../constant";
import NFTCard from "../components/NFTCard";
import { StakeTools, ClaimTools } from "../store/user/actions";

let uri_prefix = "https://atomichub-ipfs.com/ipfs/";

export const Tools = () => {
  const userModule = useSelector((state: AppState) => state.userModule);
  const loginInfo = userModule.loginInfo;
  const dispatch = useDispatch<any>();

  const [toolNfts, setToolNfts] = useState([]);
  const [filteredNfts, setFilteredNfts] = useState([]);

  const filters = ["All", "Shovel", "Pickaxe"];
  const [filter, setFilter] = useState("All");
  const [sortBy, setSortBy] = useState("rarity"); // Default sort by rarity

  const onChangeFilter = (e: any) => {
    setFilter(e.target.value);
  };

  const onChangeSortBy = (e: any) => {
    setSortBy(e.target.value);
  };

  useEffect(() => {
    let _tools = loginInfo.assets?.filter((item: any) => item?.tool_config);

    _tools?.forEach((item: any) => {
      if (typeof item.exploration_time === "string") {
        item.exploration_time =
          new Date(item.exploration_time).getTime() / 1000;
      }
      // Calculate a rarity score (higher is rarer)
    });

    setToolNfts(_tools);
  }, [loginInfo.assets]);

  useEffect(() => {
    let _filteredNfts = toolNfts;

    if (filter !== "All") {
      _filteredNfts = toolNfts.filter((item: any) =>
        item.data.name.includes(filter)
      );
    }

    // Sorting logic
    if (sortBy === "rarity") {
      _filteredNfts.sort(
        (a: any, b: any) =>
          parseFloat(b.tool_config.reward_token) +
          parseFloat(b.tool_config.reward_amount) -
          (parseFloat(a.tool_config.reward_token) +
            parseFloat(a.tool_config.reward_amount))
      );
    } else if (sortBy === "time") {
      _filteredNfts.sort(
        (a: any, b: any) => b.exploration_time - a.exploration_time
      );
    }
    console.log("filteredNfts", _filteredNfts);
    setFilteredNfts(_filteredNfts);
  }, [toolNfts, filter, sortBy]);

  const doStake = (assetId: string) => {
    let temp_ids = [
      "855129",
      "855133",
      "855136",
      "855138",
      "855143",
      "855144",
      "855146",
      "855147",
    ];
    let values = [1, 2, 5, 10];

    let _selected = toolNfts.filter((item: any) => assetId == item.asset_id);

    let reward_amount = 0;

    _selected.map((item: any) => {
      let idx = temp_ids.indexOf(item.template.template_id);
      if (idx != -1) reward_amount += values[Math.floor(idx / 2)];
    });
    dispatch(StakeTools([assetId], reward_amount, filter));
  };

  const doClaim = (assetId: any) => {
    dispatch(ClaimTools([assetId]));
  };

  const doStakeAll = () => {
    let temp_ids = [
      "855129",
      "855133",
      "855136",
      "855138",
      "855143",
      "855144",
      "855146",
      "855147",
    ];
    let values = [1, 2, 5, 10];

    let _selected = filteredNfts.filter((item: any) => !item.exploration_time);
    let _selectedIds = _selected.map((item: any) => item.asset_id);

    let reward_amount = 0;

    _selected.map((item: any) => {
      let idx = temp_ids.indexOf(item.template.template_id);
      if (idx != -1) reward_amount += values[Math.floor(idx / 2)];
    });
    dispatch(StakeTools(_selectedIds, reward_amount, filter));
  };

  const doClaimAll = () => {
    const currentUnixTime = Math.floor(new Date().getTime() / 1000);
    let _selectedIds = filteredNfts.filter(
      (item: any) => item.exploration_time <= currentUnixTime
    );
    dispatch(ClaimTools(_selectedIds));
  };

  return (
    <Box sx={{ height: "calc(100vh - 80px)", p: 2 }}>
      <Box
        sx={{
          backgroundColor: "#070508db",
          width: { xs: "90%", md: "50%" },
          margin: "auto",
          mt: { sx: 0, sm: 0, md: 4 },
          padding: { xs: "10px", sm: "10px", md: "20px" },
          borderRadius: "8px",
        }}
      >
        {/* Title with background image */}
        <Box
          sx={{
            position: "relative",
            width: "100%",
            textAlign: "center",
            mb: 4,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              position: "relative",
              zIndex: 2,
              fontWeight: "bold",
              color: "white",
              textShadow: "2px 2px 3px rgba(0,0,0,0.5)",
            }}
          >
            TOOLS
          </Typography>
          <Box
            component="img"
            src="/images/title_bg.png"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "100%", sm: "300px" },
              zIndex: 1,
            }}
          />
        </Box>

        {toolNfts && toolNfts.length > 0 ? (
          <>
            {/* Filter and Sort Section */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <select
                  className="rpc-endpoints"
                  onChange={onChangeFilter}
                  style={{
                    backgroundColor: "#333",
                    color: "white",
                    border: "1px solid #666",
                    borderRadius: "8px",
                    padding: "10px",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  {filters.map((item) => (
                    <option
                      key={item}
                      value={item}
                      style={{ backgroundColor: "#333", color: "white" }}
                    >
                      {item.toUpperCase()}
                    </option>
                  ))}
                </select>
                <select
                  className="rpc-endpoints"
                  onChange={onChangeSortBy}
                  style={{
                    backgroundColor: "#333",
                    color: "white",
                    border: "1px solid #666",
                    borderRadius: "8px",
                    padding: "10px",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                >
                  <option
                    value="rarity"
                    style={{ backgroundColor: "#333", color: "white" }}
                  >
                    Rarity
                  </option>
                  <option
                    value="time"
                    style={{ backgroundColor: "#333", color: "white" }}
                  >
                    Time
                  </option>
                </select>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#5cb85c",
                    color: "white",
                    "&:hover": { bgcolor: "#449d44" },
                    fontWeight: "bold",
                    borderRadius: "8px",
                  }}
                  onClick={doStakeAll}
                  disabled={
                    filteredNfts.filter((item: any) => !item.exploration_time)
                      .length === 0
                  }
                >
                  Stake All
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#d9534f",
                    color: "white",
                    "&:hover": { bgcolor: "#c9302c" },
                    fontWeight: "bold",
                    borderRadius: "8px",
                  }}
                  onClick={doClaimAll}
                  disabled={
                    filteredNfts.filter(
                      (item: any) =>
                        item.exploration_time < new Date().getTime() / 1000
                    ).length === 0
                  }
                >
                  Claim All
                </Button>
              </Box>
            </Box>

            {/* NFT Grid Section */}
            <Box
              sx={{
                bgcolor: "rgba(65, 48, 133, 0.4)",
                borderRadius: "16px",
                p: 2,
                width: "100%",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
                overflowY: "scroll",
                height: "50vh",
                mt: 2,
              }}
            >
              <Grid container sx={{ p: 2 }}>
                {filteredNfts?.map((item: any) => (
                  <NFTCard
                    key={item.asset_id}
                    uri={uri_prefix + item.data.img}
                    name={item.data.name}
                    assetID={item.asset_id}
                    stakedTime={item.exploration_time}
                    onClaim={() => doClaim(item)}
                    onStake={() => doStake(item.asset_id)}
                    nftData={item} // Pass nftData here
                  />
                ))}
              </Grid>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              bgcolor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "16px",
              color: "white",
              p: 4,
              textAlign: "center",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          >
            <Typography variant="h6" gutterBottom>
              There are no tool NFTs.
            </Typography>
            <Typography variant="body1">
              Buy tool NFT{" "}
              <a
                href="https://wax.atomichub.io/market?blockchain=wax-mainnet&collection_name=waxdeerjc&order=asc&primary_chain=wax-mainnet&schema_name=tools&sort=price&symbol=WAX#sales"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#d9534f" }}
              >
                Here
              </a>
              .
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
