import React from "react";
import { useEffect, useState } from "react";

import "./card.css";
import Button from '@mui/material/Button';
import { FormLabel, Box, Grid, backdropClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import Checkbox from '@mui/material/Checkbox';
import { GetTimeString } from "../../utiles";
import { useNavigate } from "react-router-dom";

// export interface CardProps {
//   menuCollapse: boolean,
//   func: (value: boolean) => void;
// }


export interface URIProp {
  asset_id: any,
  level: any,
  name: string,
  img_url: string,
  onClick?: any,
  isSelected?: any,
}

const InventoryItemCard = ({ asset_id, level, name, img_url, onClick, isSelected }: URIProp) => {


  const navigate = useNavigate();

  const [backAmount, setBackamount] = useState("");
  const [hide, setHide] = useState(false);
  let result: any;
  const btnStyle = {
    width: "100%",
    minWidth: "2px",
    color: "white",
    height: "100%",
    fontWeight: "bold",
    // backgroundColor: "#ea923e",
    borderColor: "#d3b69a",
    borderRadius: "15px",
    fontSize: "10px",
    padding: "16px"
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const box_style = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    margin: "auto",
    height: "100%",
    maxWidth: "200px",
    width: "90%",
    bgcolor: '#1a1f3c',
    borderRadius: 2,
    border: "1px solid " + (isSelected ? "#1fff57" : "black"),
    backgroundImage: "radial-gradient(circle, #5c0067 0%, #06313a 100%)",
    cursor: "pointer"
  }

  const display_none = {
    display: "none",
  }


  //------------ for model start------------

  const modal_style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#1a203c',
    color: 'white',
    border: '4px solid #000',
    boxShadow: 24,
    borderColor: '#ea923e',
    textAlign: "center",
    borderRadius: "16px",
    p: 4,
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const showNFTDetail = (id: any) => {
    console.log("asset id", id);

    if (onClick) onClick(id);
    else
      navigate("/detail/" + id);
  }

  return (
    <Grid xl={2} md={2} sm={3} xs={3} sx={{ mb: 3 }}>
      <Box sx={box_style} onClick={() => { showNFTDetail(asset_id) }}>

        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modal_style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Type stake amounts
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 5 }}>
                <TextField
                  style={{ color: "white" }}
                  id="outlined-number"
                  label="Number"
                  type="number"
                  value={backAmount}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={() => { }}
                />
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Button variant="outlined" sx={{ mr: 2 }} onClick={handleClose}>Cancel</Button>
                <Button variant="contained" >Confirm</Button>
              </Typography>
            </Box>
          </Modal>
        </div>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <Checkbox {...label} /> */}
          <Typography className="name" variant="h6" sx={{ fontSize: { xs: "12px", sm: "12px", md: "20px" }, color: "white", padding: "8px", flexGrow: 1 }} component="p">
            Level : {level}
          </Typography>
        </Box>
        <Box style={{ width: "100%", margin: "auto" }}>
          <Box sx={{ width: "90%", textAlign: "center", margin: "auto" }}>
            <img style={{ width: "100%", borderRadius: "4px" }} src={img_url} alt="card" />
          </Box>
        </Box>
        <Box style={{ width: "100%", margin: "auto", marginBottom: "0px" }}>
          <Box>
            <Typography className="name" variant="h6" sx={{ fontSize: { xs: "12px", sm: "12px", md: "20px" }, width: "90%", margin: "auto", color: "white", flexGrow: 1 }} component="p">
              {name}
            </Typography>
          </Box>
          <Grid container style={{ justifyContent: "space-between", width: "90%", margin: "auto", marginTop: "5px" }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  bgcolor: '#ea923e',
                  fontWeight: "bold",
                  boxShadow: 1,
                  borderRadius: 3,
                  width: "100%",
                  height: "70%"
                }}
              >
                {/* <Button sx={btnStyle} onClick={() => { }}>Exploration</Button> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid >
  );
};

export default InventoryItemCard;