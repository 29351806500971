import { Box } from "@mui/material"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { GetLeaderBoard } from "../store/user/actions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: "white",
}));

export const LeaderBoard = () => {

    const dispatch = useDispatch<any>();
    const userModule = useSelector((state: AppState) => state.userModule);
    const loginInfo = userModule.loginInfo;
    const gameStatus = userModule.gameStatus;

    const [scores, setScores] = useState([]);
    const [myScore, setMyScore] = useState<any>(null);

    useEffect(() => {

        dispatch(GetLeaderBoard());
    }, []);

    useEffect(() => {

        if (gameStatus.leaderboard) {

            setScores(gameStatus.leaderboard.sort((a: any, b: any) => b.experience - a.experience));
        }
        if (gameStatus.myScore) {
            setMyScore(gameStatus.myScore);
        }


    }, [JSON.stringify(gameStatus.leaderboard)]);

    return (<>
        <Box sx={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0, backgroundSize: "cover", backgroundPosition: "center", backgroundImage: "url(/images/leaderboard_main_bg.png)" }}>
            <Box sx={{ marginTop: "64px", height: 'calc(100vh - 80px)' }}>
                <Box sx={{ p: { xs: 1, sm: 1, md: 10 }, boxSizing: "border-box", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img src="/images/leaderboard_bg.png" style={{ maxWidth: "1150px", zIndex: 1, position: "absolute", height: "80vh" }} />
                    <h1 style={{ color: "white", zIndex: 2, marginTop: "5vh" }}>LEADERBOARD </h1>
                    <Box sx={{ width: "80%", maxWidth: "900px", overflowY: "scroll", zIndex: 2, marginTop: "3vh", height: "61vh" }}>
                        <TableContainer component={Paper} sx={{ background: "none" }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Rank</StyledTableCell>
                                        <StyledTableCell align="center">Account</StyledTableCell>
                                        <StyledTableCell align="center">Game Token</StyledTableCell>
                                        <StyledTableCell align="center">Experience</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {scores.map((row: any, index: number) => (
                                        <TableRow
                                            key={row.username}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                backgroundColor: myScore?.username == row.username ? "#4d2e17" : "none"
                                            }}
                                        >
                                            <StyledTableCell component="th" scope="row" align="center">
                                                {index == 0 && <EmojiEventsIcon sx={{ color: "yellow" }} />}
                                                {index == 1 && <EmojiEventsIcon sx={{ color: "white" }} />}
                                                {index == 2 && <EmojiEventsIcon sx={{ color: "chocolate" }} />}
                                                {index > 2 && (index + 1)}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{row.username}</StyledTableCell>
                                            <StyledTableCell align="center">{row.in_game_token}</StyledTableCell>
                                            <StyledTableCell align="center">{row.experience}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                    {myScore && scores.length <= myScore.rank && <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: "#421d6b" }}
                                    >
                                        <StyledTableCell component="th" scope="row" align="center">{myScore.rank}</StyledTableCell>
                                        <StyledTableCell align="center">{myScore.username}</StyledTableCell>
                                        <StyledTableCell align="center">{myScore.in_game_token}</StyledTableCell>
                                        <StyledTableCell align="center">{myScore.experience}</StyledTableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box >
        </Box>
    </>);
}