import { SessionKit } from "@wharfkit/session";
import { WebRenderer } from "@wharfkit/web-renderer";
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor";
import { WalletPluginCloudWallet } from "@wharfkit/wallet-plugin-cloudwallet";
import { WalletPluginWombat } from "@wharfkit/wallet-plugin-wombat";
import { Api, JsonRpc } from "eosjs";

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  WORLD_SELECT,
  EXPLORATION_SUCCESS,
  EXPLORATION_ERROR,
  BUY_WORLD,
  SHOW_ALERT,
  SHOW_LOADING,
  HIDE_LOADING,
  CLAIM_STAKE,
  STAKE_SUCCESS,
  BUY_GOLD,
  GET_LEADERBOARD,
  GET_CONFIGS,
  STAKE_TOOL_SUCCESS,
  LEVELUP_SUCCESS,
  LEVELUP_BOOST_SUCCESS,
  GET_TOURNAMENT_LIST,
  ENTER_TOURNAMENT_SUCCESS,
} from "./action-types";
import {
  assetsEndpoint,
  chainEndpoint,
  hyperionEndpoint,
  COLLECTION,
  GAME_CONTRACT,
  TOKEN_CONTRACT,
  TOKEN_SYMBOL,
  GOLD_TOKEN_SYMBOL,
  GOLD_TOKEN_CONTRACT,
  SYSTEM_TOKEN_CONTRACT,
  SYSTEM_TOKEN_SYMBOL,
  FEED_TOKEN_SYMBOL,
  FEED_TOKEN_CONTRACT,
} from "../../constant";

const webRenderer = new WebRenderer();

console.log("chainEndpoint", chainEndpoint);

const sessionKit = new SessionKit({
  appName: "wax deer exploration",
  chains: [chainEndpoint],
  ui: webRenderer,
  walletPlugins: [
    new WalletPluginAnchor(),
    new WalletPluginCloudWallet(),
    new WalletPluginWombat(),
  ],
});

export function isSessionKitReady() {
  return sessionKit || false;
}
export function ChangeEndPoint(endpoint: string) {
  return async (dispatch: any) => {
    try {
      sessionKit.chains[0].url = endpoint;
      console.log("sessionKit success");
    } catch (e) {
      console.log("sessionKit failed");
      console.log(e);
    }
  };
}

async function loadData(dispatch: any, account: string, session: any) {
  try {
    dispatch({
      type: SHOW_LOADING,
      payload: {},
    });

    // account = "rzjhy.wam";

    let moreAssets = true;
    let page = 1;
    let assets: any = [];

    while (moreAssets) {
      var path =
        "atomicassets/v1/assets?collection_name=" +
        COLLECTION +
        "&owner=" +
        account +
        "&page=" +
        page +
        "&limit=1000&order=desc&sort=asset_id";

      console.log(path, assetsEndpoint);

      const res = await fetch(assetsEndpoint + path, {
        headers: {
          "Content-Type": "text/plain",
        },
        method: "POST",
      });

      const body = await res.json();
      if (body.data && body.data?.length > 0) {
        assets = assets.concat(body.data);
        page++;
      } else moreAssets = false;
    }

    assets?.sort((a: any, b: any) => a.asset_id - b.asset_id);

    let token: any = await getTokenBalance(SYSTEM_TOKEN_CONTRACT, account);
    let wax_balance: any = "0 WAX";
    let grass_balance: any = "0 GRASS";
    let gold_balance: any = "0 GOLD";
    let xp_balance: any = "0 GOLD";

    if (token && token.length > 0) wax_balance = token[0].balance;

    token = await getTokenBalance(FEED_TOKEN_CONTRACT, account);
    if (token && token.length > 0) {
      token.forEach((tok: any) => {
        if (tok.balance.includes("GRASS")) {
          grass_balance = tok.balance;
        }
      });
    }

    const rpc = new JsonRpc(sessionKit.chains[0].url);

    let result = await rpc.get_table_rows({
      json: true, // Return the result as JSON
      code: GAME_CONTRACT, // Contract name
      scope: GAME_CONTRACT, // Scope (typically the same as the contract)
      table: "players", // Table name
      limit: 1, // Number of rows to fetch
      reverse: false, // Set to true to get rows in reverse order
      show_payer: false, // Show payer information
      lower_bound: session.actor,
      upper_bound: session.actor,
    });
    if (result.rows.length > 0) {
      gold_balance = result.rows[0].in_game_token;
      xp_balance = result.rows[0].experience;
    }

    result = await rpc.get_table_rows({
      json: true, // Return the result as JSON
      code: GAME_CONTRACT, // Contract name
      scope: GAME_CONTRACT, // Scope (typically the same as the contract)
      table: "userworld", // Table name
      lower_bound: session.actor,
      upper_bound: session.actor,
      limit: 1, // Number of rows to fetch
      reverse: false, // Set to true to get rows in reverse order
      show_payer: false, // Show payer information
    });
    let open_worlds = [];
    if (result.rows.length > 0) open_worlds = result.rows[0].openworlds;

    result = await rpc.get_table_rows({
      json: true, // Return the result as JSON
      code: GAME_CONTRACT, // Contract name
      scope: GAME_CONTRACT, // Scope (typically the same as the contract)
      table: "worldcost", // Table name
      limit: 3, // Number of rows to fetch
      reverse: false, // Set to true to get rows in reverse order
      show_payer: false, // Show payer information
    });
    let world_cost = result.rows;
    result = await rpc.get_table_rows({
      json: true, // Return the result as JSON
      code: GAME_CONTRACT, // Contract name
      scope: GAME_CONTRACT, // Scope (typically the same as the contract)
      table: "stakeinfo", // Table name
      limit: 6, // Number of rows to fetch
      reverse: false, // Set to true to get rows in reverse order
      show_payer: false, // Show payer information
    });
    let reward_info = result.rows;
    let more = true;
    let lower_bound = 0;
    let explored_nfts = [] as any;
    while (more) {
      result = await rpc.get_table_rows({
        json: true, // Return the result as JSON
        code: GAME_CONTRACT, // Contract name
        scope: GAME_CONTRACT, // Scope (typically the same as the contract)
        table: "exploration", // Table name
        lower_bound: lower_bound,
        upper_bound: "",
        limit: 1000, // Number of rows to fetch
        reverse: false, // Set to true to get rows in reverse order
        show_payer: false, // Show payer information
      });
      if (result.rows.length < 1000) more = false;
      lower_bound = result.rows[result.rows.length - 1].asset_id;
      explored_nfts = explored_nfts.concat(result.rows as any);
    }
    result = await rpc.get_table_rows({
      json: true, // Return the result as JSON
      code: GAME_CONTRACT, // Contract name
      scope: GAME_CONTRACT, // Scope (typically the same as the contract)
      table: "pools", // Table name
      limit: 10, // Number of rows to fetch
      reverse: false, // Set to true to get rows in reverse order
      show_payer: false, // Show payer information
    });
    let pool_info = result.rows;
    result = await rpc.get_table_rows({
      json: true, // Return the result as JSON
      code: GAME_CONTRACT, // Contract name
      scope: GAME_CONTRACT, // Scope (typically the same as the contract)
      table: "stakers", // Table name
      lower_bound: session.actor,
      upper_bound: session.actor,
      limit: 10000, // Number of rows to fetch
      reverse: false, // Set to true to get rows in reverse order
      show_payer: false, // Show payer information
    });

    let staking_pool = [];
    if (result.rows.length > 0) staking_pool = result.rows[0].stakeList;
    // Update explored NFT time
    result = await rpc.get_table_rows({
      json: true, // Return the result as JSON
      code: GAME_CONTRACT, // Contract name
      scope: GAME_CONTRACT, // Scope (typically the same as the contract)
      table: "rewardinfos", // Table name
      limit: 1000, // Number of rows to fetch
      reverse: false, // Set to true to get rows in reverse order
      show_payer: false, // Show payer information
    });
    let tool_configs = result.rows;

    assets = assets?.map((item: any) => {
      const new_item = { ...item };

      let rlt = explored_nfts?.find(
        (nft: any) => nft.asset_id == new_item.asset_id
      );
      if (rlt) {
        new_item.exploration_time = rlt.last_updated; //Math.max(0, rlt.last_updated + 86400 - currentUnixTime);
        new_item.upgrade_end_at = rlt.upgrade_end_at;
        new_item.level = rlt.level;
      } else {
        new_item.exploration_time = 0;
        new_item.upgrade_end_at = 0;
        new_item.level = 1;
      }

      const foundInConfig = tool_configs?.find(
        (nft: any) =>
          parseInt(nft.template_id) == parseInt(new_item.template?.template_id)
      );

      if (foundInConfig) {
        new_item.tool_config = foundInConfig;
      }

      return new_item;
    });
    console.log("All_Assets", assets);
    dispatch({
      type: HIDE_LOADING,
      payload: {},
    });

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        session,
        balance: { wax_balance, grass_balance, gold_balance, xp_balance },
        assets,
        world_cost,
        open_worlds,
        reward_info,
        staking_pool,
        pool_info,
      },
    });
  } catch (e: any) {
    dispatch({
      type: HIDE_LOADING,
      payload: {},
    });
    dispatch({
      type: SHOW_ALERT,
      payload: { type: "error", msg: e.toString(), show: true },
    });
  }
}

export function AutoLogin() {
  return async (dispatch: any) => {
    try {
      let session: any = await sessionKit.restore();
      console.log(session);
      let account = session.permissionLevel.actor;
      console.log(account?.toString());
      await loadData(dispatch, account, session);
    } catch (e: any) {}
  };
}

async function Reload(dispatch: any) {
  let session: any = await sessionKit.restore();
  let account = session.permissionLevel.actor;
  await loadData(dispatch, account, session);
}

export function WalletLogin() {
  return async (dispatch: any) => {
    try {
      const response = await sessionKit.login();
      const checkrestored = await sessionKit.restore();
      let session: any = checkrestored || response;

      if (!session) {
        return dispatch({
          type: LOGIN_ERROR,
          payload: {},
        });
      }
      let account = session.permissionLevel.actor;
      await loadData(dispatch, account, session);
    } catch (e: any) {}
  };
}

async function getTokenBalance(token_contract: any, account: any) {
  try {
    const rpc = new JsonRpc(sessionKit.chains[0].url);
    let session: any = await sessionKit.restore();

    // Fetch the account's balances
    const result = await rpc.get_table_rows({
      json: true,
      code: token_contract, // The contract name for the token
      scope: account,
      table: "accounts",
      limit: 100, // Adjust as needed
    });

    // Check if there are any balances
    if (result.rows.length > 0) {
      return result.rows;
      result.rows.forEach((row) => {
        console.log(`Token: ${row.balance}`);
      });
    } else {
      console.log("No token balances found for this account.");
      return null;
    }
  } catch (error) {
    return null;
    console.error("Error fetching balance:", error);
  }
}

export const getRewardTokenBalance = async (
  toAccount: any,
  fromAccount: any
) => {
  const requestString = `${hyperionEndpoint}?account=${toAccount}&filter=${fromAccount}`;

  const response = await fetch(requestString);
  const result = await response.json();

  if (result.actions) {
    console.log(result.actions);
    // Filter actions based on your criteria (e.g., `to`, `from` fields)
    const filteredActions = result.actions.filter((action: any) => {
      const act = action.act;
      return (
        act.account == TOKEN_CONTRACT &&
        act.name == "transfer" &&
        act.data.to == toAccount &&
        act.data.from == fromAccount
      );
    });
    console.log(filteredActions[0].act.data.quantity);
    return filteredActions[0].act.data.quantity;
  } else {
    console.error("Error fetching actions:", result);
    return "";
  }
};

export function BuyWorldAction(world: number, callback: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: SHOW_LOADING,
        payload: {},
      });

      let session: any = await sessionKit.restore();

      // update this based on smart contract
      let world_cost = [0, 0, 100, 500];

      let memo = "open_world:" + world;

      // Prepare the action data
      const data = {
        account: TOKEN_CONTRACT,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: GAME_CONTRACT,
          quantity: world_cost[world].toFixed(4) + " " + TOKEN_SYMBOL,
          memo,
        },
      };

      const result = await session.transact({ action: data });
      // const result = true;

      if (result) {
        dispatch({
          type: BUY_WORLD,
          payload: { world },
        });

        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "success",
            msg: "Successfully purchased the world-" + world + ".",
            show: true,
          },
        });

        callback(true);
      } else {
        dispatch({
          type: EXPLORATION_ERROR,
          payload: { error: { message: "Exploration Error" } },
        });
        callback(false);
      }

      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
    } catch (e: any) {
      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
      dispatch({
        type: SHOW_ALERT,
        payload: { type: "error", msg: e.toString(), show: true },
      });
      callback(false);
    }
  };
}

export function SetWorldAction(world: number) {
  return async (dispatch: any) => {
    dispatch({
      type: WORLD_SELECT,
      payload: { world },
    });
  };
}

export function Exploration(
  world: number,
  asset_ids: Array<any>,
  is_promo: boolean
) {
  return async (dispatch: any) => {
    try {
      let session: any = await sessionKit.restore();

      let amount = (asset_ids.length * 0.01).toFixed(4) + " " + TOKEN_SYMBOL;
      let memo = "exploration:" + world + ":" + asset_ids.join(",");

      dispatch({
        type: SHOW_LOADING,
        payload: {},
      });

      const data1 = {
        account: FEED_TOKEN_CONTRACT,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: GAME_CONTRACT,
          quantity:
            (asset_ids.length / 2.0).toFixed(4) + " " + FEED_TOKEN_SYMBOL,
          memo: "explore:",
        },
      };

      const data2 = {
        account: GAME_CONTRACT,
        name: "explore",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          asset_ids: asset_ids,
          type: world,
        },
      };

      let args: any = {
        actions: [data1, data2],
      };

      if (is_promo) {
        args = {
          actions: [data2],
        };
      }
      // console.log(data);

      const result = await session.transact(args);

      // const result = true;

      if (result) {
        dispatch({
          type: EXPLORATION_SUCCESS,
          payload: { asset_ids, type: world },
        });

        // action history
        console.log("action history");
        const reward_token_amount = await getRewardTokenBalance(
          session.actor,
          GAME_CONTRACT
        );
        console.log(reward_token_amount);

        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "success",
            msg: `Received ${reward_token_amount} reward successfully`,
            show: true,
          },
        });
      } else {
        dispatch({
          type: EXPLORATION_ERROR,
          payload: { error: { message: "Exploration Error" } },
        });
      }

      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
    } catch (e: any) {
      console.log(e);
      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
      dispatch({
        type: SHOW_ALERT,
        payload: { type: "error", msg: e.toString(), show: true },
      });
    }
  };
}

export function StakePool(pool_id: number, amount: number) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: SHOW_LOADING,
        payload: {},
      });

      let session: any = await sessionKit.restore();
      let memo = "staking:" + pool_id;

      // Prepare the action data
      const data = {
        account: TOKEN_CONTRACT,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: GAME_CONTRACT,
          quantity: amount.toFixed(4) + " " + TOKEN_SYMBOL,
          memo,
        },
      };

      const result = await session.transact({ action: data });

      if (result) {
        dispatch({
          type: STAKE_SUCCESS,
          payload: { pool_id, amount: amount.toFixed(4) + " " + TOKEN_SYMBOL },
        });

        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "success",
            msg: "Successfully staked " + amount + TOKEN_SYMBOL,
            show: true,
          },
        });
      } else {
        dispatch({
          type: EXPLORATION_ERROR,
          payload: { error: { message: "Exploration Error" } },
        });
      }

      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
    } catch (e: any) {
      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
      dispatch({
        type: SHOW_ALERT,
        payload: { type: "error", msg: e.toString(), show: true },
      });
    }
  };
}
export function ClaimStakePool(pool_id: number, reward_amount: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: SHOW_LOADING,
        payload: {},
      });

      let session: any = await sessionKit.restore();

      const data = {
        account: GAME_CONTRACT,
        name: "claimstake",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          pool_id,
        },
      };

      const result = await session.transact({ action: data });

      if (result) {
        dispatch({
          type: CLAIM_STAKE,
          payload: { pool_id },
        });

        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "success",
            msg: "Successfully claimed reward " + reward_amount,
            show: true,
          },
        });
      } else {
        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "error",
            msg: "Error while claiming reward",
            show: true,
          },
        });
      }

      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
    } catch (e: any) {
      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
      dispatch({
        type: SHOW_ALERT,
        payload: { type: "error", msg: e.toString(), show: true },
      });
    }
  };
}

export function ClaimTools(ids: Array<any>) {
  return async (dispatch: any) => {
    try {
      console.log(ids);
      dispatch({
        type: SHOW_LOADING,
        payload: {},
      });

      let session: any = await sessionKit.restore();

      const data = {
        account: GAME_CONTRACT,
        name: "staketool",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          asset_ids: ids?.map((item: any) => item.asset_id) || [],
          type: 1,
        },
      };

      var tgrass = 0;
      var txp = 0;
      for (var i = 0; i < ids.length; i++) {
        var item = ids[i];
        tgrass += parseFloat(item.tool_config.reward_token);
        txp += parseFloat(item.tool_config.reward_amount);
      }
      console.log(tgrass);

      const result = await session.transact({ action: data });

      if (result) {
        dispatch({
          type: STAKE_TOOL_SUCCESS,
          payload: { ids, tgrass },
        });
        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "success",
            msg:
              "You got " +
              (tgrass > 0 ? tgrass + " Grass" : "0 Grass") +
              (txp > 0 ? " and" + txp + " XP" : "-"),
            show: true,
          },
        });
        await Reload(dispatch);
      } else {
        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "error",
            msg: "Error while staking tools",
            show: true,
          },
        });
      }

      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
    } catch (e: any) {
      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
      dispatch({
        type: SHOW_ALERT,
        payload: { type: "error", msg: e.toString(), show: true },
      });
    }
  };
}
export function StakeTools(
  ids: Array<any>,
  reward_amount: number,
  type: string
) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: SHOW_LOADING,
        payload: {},
      });

      let session: any = await sessionKit.restore();

      const data = {
        account: GAME_CONTRACT,
        name: "staketool",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          asset_ids: ids,
          type: 1,
        },
      };

      const result = await session.transact({ action: data });

      if (result) {
        dispatch({
          type: STAKE_TOOL_SUCCESS,
          payload: { ids, type },
        });

        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "success",
            msg:
              "You got " +
              reward_amount +
              (type == "Shovel" ? "GRASS" : "Exp") +
              " successfully.",
            show: true,
          },
        });
      } else {
        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "error",
            msg: "Error while staking tools",
            show: true,
          },
        });
      }

      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
    } catch (e: any) {
      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
      dispatch({
        type: SHOW_ALERT,
        payload: { type: "error", msg: e.toString(), show: true },
      });
      await Reload(dispatch);
    }
  };
}

export function BuyGameTokenAction(amount: any, receive_amount: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: SHOW_LOADING,
        payload: {},
      });

      let session: any = await sessionKit.restore();

      let memo = "buy:gold";

      // Prepare the action data
      const data = {
        account: SYSTEM_TOKEN_CONTRACT,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: GAME_CONTRACT,
          quantity: Number(amount).toFixed(8) + " " + SYSTEM_TOKEN_SYMBOL,
          memo,
        },
      };

      const result = await session.transact({ action: data });
      // const result = true;

      if (result) {
        dispatch({
          type: BUY_GOLD,
          payload: { amount, receive_amount },
        });

        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "success",
            msg:
              "Successfully bought " +
              receive_amount +
              " " +
              GOLD_TOKEN_SYMBOL +
              ".",
            show: true,
          },
        });
      } else {
        dispatch({
          type: SHOW_ALERT,
          payload: { type: "error", msg: "Buy Gold Error", show: true },
        });
      }

      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
    } catch (e: any) {
      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
      dispatch({
        type: SHOW_ALERT,
        payload: { type: "error", msg: e.toString(), show: true },
      });
    }
  };
}

export function LevelUpgrade(asset_id: any, current_level: any) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: SHOW_LOADING,
        payload: {},
      });

      let session: any = await sessionKit.restore();

      let memo = "upgrade:" + asset_id;

      // Prepare the action data
      const data = {
        account: FEED_TOKEN_CONTRACT,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: {
          from: session.actor,
          to: GAME_CONTRACT,
          quantity:
            ((current_level * current_level) / 2).toFixed(4) +
            " " +
            FEED_TOKEN_SYMBOL,
          memo,
        },
      };

      const result = await session.transact({ action: data });
      // const result = true;

      if (result) {
        dispatch({
          type: LEVELUP_SUCCESS,
          payload: { asset_id, current_level },
        });

        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "success",
            msg: "Successfully levelup  " + asset_id,
            show: true,
          },
        });
      } else {
        dispatch({
          type: SHOW_ALERT,
          payload: { type: "error", msg: "Levelup Error", show: true },
        });
      }

      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
    } catch (e: any) {
      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
      dispatch({
        type: SHOW_ALERT,
        payload: { type: "error", msg: e.toString(), show: true },
      });
    }
  };
}

export function LevelupBoost(asset_id: any, boostAmount: number) {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: SHOW_LOADING,
        payload: {},
      });

      let session: any = await sessionKit.restore();

      // Prepare the action data
      const data = {
        account: GAME_CONTRACT,
        name: "boostlvlup",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          asset_id: asset_id,
          amount: boostAmount,
        },
      };

      const result = await session.transact({ action: data });
      // const result = true;

      if (result) {
        dispatch({
          type: LEVELUP_BOOST_SUCCESS,
          payload: { asset_id, boostAmount },
        });

        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "success",
            msg: "Successfully boost " + asset_id,
            show: true,
          },
        });
      } else {
        dispatch({
          type: SHOW_ALERT,
          payload: { type: "error", msg: "Boost Error", show: true },
        });
      }

      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
    } catch (e: any) {
      dispatch({
        type: HIDE_LOADING,
        payload: {},
      });
      dispatch({
        type: SHOW_ALERT,
        payload: { type: "error", msg: e.toString(), show: true },
      });
    }
  };
}

export function EnterTournament(asset_ids: Array<any>, id: any) {
  return async (dispatch: any) => {
    let session: any = await sessionKit.restore();

    const data1 = {
      account: TOKEN_CONTRACT,
      name: "transfer",
      authorization: [session.permissionLevel],
      data: {
        from: session.actor,
        to: GAME_CONTRACT,
        quantity: asset_ids.length.toFixed(4) + " " + TOKEN_SYMBOL,
        memo: "tournament:" + id,
      },
    };

    const data2 = {
      account: GAME_CONTRACT,
      name: "entertour",
      authorization: [session.permissionLevel],
      data: {
        user: session.actor,
        asset_ids: asset_ids,
        tournament_id: id,
        memo: "enter tournament",
      },
    };

    const args: any = {
      actions: [data1, data2],
    };
    const result = await session.transact(args);

    if (result) {
      dispatch({
        type: ENTER_TOURNAMENT_SUCCESS,
        payload: { id },
      });

      dispatch({
        type: SHOW_ALERT,
        payload: {
          type: "success",
          msg: "Successfully entered in Tournament",
          show: true,
        },
      });
    }
  };
}

export function GetTournamentList() {
  return async (dispatch: any) => {
    const rpc = new JsonRpc(sessionKit.chains[0].url);
    let session: any = await sessionKit.restore();

    const result = await rpc.get_table_rows({
      json: true, // Return the result as JSON
      code: GAME_CONTRACT, // Contract name
      scope: GAME_CONTRACT, // Scope (typically the same as the contract)
      table: "tournaments", // Table name
      limit: 10, // Number of rows to fetch
      reverse: false, // Set to true to get rows in reverse order
      show_payer: false, // Show payer information
    });

    const tournaments = result.rows;

    dispatch({
      type: GET_TOURNAMENT_LIST,
      payload: { tournaments },
    });
  };
}

export function GetConfigs() {
  return async (dispatch: any) => {
    const rpc = new JsonRpc(sessionKit.chains[0].url);
    let session: any = await sessionKit.restore();

    let result = await rpc.get_table_rows({
      json: true, // Return the result as JSON
      code: GAME_CONTRACT, // Contract name
      scope: GAME_CONTRACT, // Scope (typically the same as the contract)
      table: "rewardinfos", // Table name
      limit: 1000, // Number of rows to fetch
      reverse: false, // Set to true to get rows in reverse order
      show_payer: false, // Show payer information
    });
    let data = result.rows;

    dispatch({
      type: GET_CONFIGS,
      payload: { data },
    });
  };
}

export function GetLeaderBoard() {
  return async (dispatch: any) => {
    const rpc = new JsonRpc(sessionKit.chains[0].url);
    let session: any = await sessionKit.restore();

    let result = await rpc.get_table_rows({
      json: true, // Return the result as JSON
      code: GAME_CONTRACT, // Contract name
      scope: GAME_CONTRACT, // Scope (typically the same as the contract)
      table: "players", // Table name
      limit: 1000, // Number of rows to fetch
      reverse: false, // Set to true to get rows in reverse order
      show_payer: false, // Show payer information
    });
    let leaderboard = result.rows;

    result = await rpc.get_table_rows({
      json: true, // Return the result as JSON
      code: GAME_CONTRACT, // Contract name
      scope: GAME_CONTRACT, // Scope (typically the same as the contract)
      table: "players", // Table name
      limit: 1, // Number of rows to fetch
      reverse: false, // Set to true to get rows in reverse order
      show_payer: false, // Show payer information
      lower_bound: session.actor,
      upper_bound: session.actor,
    });

    const myScore = result.rows[0];

    dispatch({
      type: GET_LEADERBOARD,
      payload: { leaderboard, myScore },
    });
  };
}

export function ShowAlert(type: string, msg: string, show: boolean) {
  return async (dispatch: any) => {
    dispatch({
      type: SHOW_ALERT,
      payload: { type, msg, show },
    });
  };
}

export function WalletLogout() {
  return async (dispatch: any) => {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        session: null,
        balance: 0,
        assets: [],
        world_cost: [],
        open_worlds: [1],
        reward_info: [],
      },
    });
  };
}

export function LoginRequest(id: number) {
  return {
    type: LOGIN_REQUEST,
    payload: {},
  };
}

export function showLoading(isShow: boolean) {
  return async (dispatch: any) => {
    dispatch({
      type: isShow ? SHOW_LOADING : HIDE_LOADING,
      payload: {},
    });
  };
}
