import { Header } from "../components/Header";
import NFTCard from "../components/NFTCard";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  Box,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Exploration, getRewardTokenBalance } from "../store/user/actions";
import { useNavigate } from "react-router-dom";
import { AppState } from "../store";
import { NFT_TYPE, MODE, GAME_CONTRACT } from "../constant";
import { toast } from "react-toastify";

let uri_prefix = "https://ipfs.neftyblocks.io/ipfs/";
let abyssal_temp = "QmesXd95twNg9hyR8NVB2b4ZDB8roCoLfjr7dtD1SZ8NAm";
const MAX_SELECT_COUNT = 5; // Set the maximum selection limit

export const Home = () => {
  const userModule = useSelector((state: AppState) => state.userModule);
  const loginInfo = userModule.loginInfo;
  const gameStatus = userModule.gameStatus;

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [selectedNfts, setSelectedNfts] = useState<string[]>([]); // Array to store selected asset IDs
  const [nfts, setNfts] = useState<any>([]);
  const [filteredNfts, setFilteredNfts] = useState<any>([]);
  const [alignment, setAlignment] = useState(NFT_TYPE);
  const [switchChecked, setSwitchChecked] = useState(false);

  useEffect(() => {
    if (!loginInfo.isLogin) {
      navigate("/");
    }

    setNfts(loginInfo.assets);
    filterAndSortNFTs(loginInfo.assets, alignment);
  }, [loginInfo.isLogin, loginInfo.assets]);

  const filterAndSortNFTs = (assets: any, type: string) => {
    if (!assets) return;

    let _filteredNfts = assets.filter(
      (item: any) => item.schema.schema_name === type
    );

    if (type === "promo") {
      _filteredNfts = _filteredNfts.filter(
        (item: any) => item.template.template_id === "859701"
      );
    }

    _filteredNfts?.sort((a: any, b: any) => {
      if (a.exploration_time !== b.exploration_time) {
        return a.exploration_time - b.exploration_time;
      } else {
        const rarityOrder = {
          Common: 1,
          Uncommon: 2,
          Rare: 3,
          Epic: 4,
          Legendary: 5,
          Mythic: 6,
        } as { [key: string]: number };
        const rarityA = a.data.Rarity ? rarityOrder[a.data.Rarity] : 0;
        const rarityB = b.data.Rarity ? rarityOrder[b.data.Rarity] : 0;
        return rarityB - rarityA;
      }
    });
    _filteredNfts?.sort((a: any, b: any) => {
      return b.level - a.level;
    });
    console.log(_filteredNfts);
    setFilteredNfts(_filteredNfts);
    setSelectedNfts([]); // Clear selected NFTs when filter changes
  };

  const DoExploration = () => {
    if (selectedNfts.length === 0) {
      toast.error("Please select at least one NFT.");
      return;
    }

    dispatch(
      Exploration(gameStatus.world, selectedNfts, alignment === "promo")
    ).then((res: any) => {
      if (res?.error) {
        toast.error(res.error);
      } else {
        toast.success("Exploration Successful!");
      }
    });
  };

  const handleChangeNFTType = (e: any, value: string) => {
    if (value === "" || value === null) return;
    setAlignment(value);
    filterAndSortNFTs(nfts, value);
  };

  const switchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchChecked(event.target.checked);
  };

  // Handle NFT Selection/Deselection
  const handleNFTClick = (assetId: string) => {
    if (selectedNfts.includes(assetId)) {
      setSelectedNfts(selectedNfts.filter((id) => id !== assetId));
    } else {
      if (selectedNfts.length < MAX_SELECT_COUNT) {
        setSelectedNfts([...selectedNfts, assetId]);
      } else {
        toast.error(`You can select a maximum of ${MAX_SELECT_COUNT} NFTs.`);
      }
    }
  };

  // Handle Select All
  const handleSelectAll = () => {
    if (
      selectedNfts.length === filteredNfts.length ||
      selectedNfts.length === MAX_SELECT_COUNT
    ) {
      // If all are selected, or if max limit is reached, deselect all
      setSelectedNfts([]);
    } else {
      // Select all filtered NFTs up to the max limit
      const newSelectedNfts = filteredNfts
        .slice(0, MAX_SELECT_COUNT)
        .map((nft: any) => nft.asset_id);
      setSelectedNfts(newSelectedNfts);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "block",
          },
          textAlign: "left",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          sx={{ height: { xs: "24px", sm: "24px", md: "36px" }, m: 2 }}
          onClick={() => {
            navigate("/explore");
          }}
        >
          Back
        </Button>
      </Box>

      <Box
        sx={{
          backgroundColor: "#070508db",
          width: { xs: "90%", md: "50%" },
          margin: "auto",
          mt: { sx: 0, sm: 0, md: 4 },
          padding: { xs: "10px", sm: "10px", md: "20px" },
          borderRadius: "8px",
        }}
      >
        <Box sx={{ fontSize: { sm: "16px", md: "30px" }, color: "LIGHTGREEN" }}>
          Select NFTs for exploration
        </Box>

        <Box sx={{ textAlign: "right", mt: 2 }}>
          <Box
            sx={{
              display: "inline-block",
              backgroundColor: "#66cdaa",
              borderRadius: "50px",
            }}
          >
            <ToggleButtonGroup
              color="secondary"
              value={alignment}
              exclusive
              onChange={handleChangeNFTType}
              aria-label="Platform"
              sx={{
                textAlign: "right",
                color: "white",
              }}
            >
              <ToggleButton
                value={NFT_TYPE}
                color="primary"
                sx={{ height: "30px" }}
              >
                {NFT_TYPE}
              </ToggleButton>
              <ToggleButton
                value="abyssal"
                color="primary"
                sx={{ height: "30px" }}
              >
                Abyssal
              </ToggleButton>
              <ToggleButton
                value="promo"
                color="primary"
                sx={{ height: "30px" }}
              >
                Promo
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        {alignment === "abyssal" && (
          <FormGroup>
            <FormControlLabel
              sx={{ justifyContent: "end" }}
              control={
                <Switch checked={switchChecked} onChange={switchChange} />
              }
              label="Show Image"
            />
          </FormGroup>
        )}

        <Box sx={{ textAlign: "center", my: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSelectAll}>
            {selectedNfts.length === filteredNfts.length ||
            selectedNfts.length === MAX_SELECT_COUNT
              ? "Deselect All"
              : `Select All (Max ${MAX_SELECT_COUNT})`}
          </Button>
        </Box>

        <Box
          sx={{
            maxHeight: "600px",
            overflowY: "scroll",
            height: "50vh",
            mt: 2,
          }}
        >
          <Grid container sx={{ p: 2 }}>
            {filteredNfts?.map((item: any, index: number) => (
              <NFTCard
                key={item.asset_id}
                uri={
                  uri_prefix +
                  (alignment === "abyssal" && !switchChecked
                    ? abyssal_temp
                    : item.data.img)
                }
                name={item.data.name}
                assetID={item.asset_id}
                stakedTime={item.exploration_time}
                isSelected={selectedNfts.includes(item.asset_id)}
                onClick={handleNFTClick} // Pass the click handler
                nftData={item}
              />
            ))}
          </Grid>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography
            className="name"
            variant="h6"
            component="p"
            sx={{
              flexGrow: 1,
              fontSize: { sm: "16px", md: "30px" },
              color: "white",
              padding: "8px",
            }}
          >
            {selectedNfts.length}{" "}
            {selectedNfts.length === 1 ? "NFT is" : "NFTs are"} Selected
          </Typography>
          <Button color="primary" variant="contained" onClick={DoExploration}>
            Explore
          </Button>
        </Box>
      </Box>
    </>
  );
};
